.IA_permissionsEditPanel {
  position: relative;
  width: 100%;
  float: left;
}

.IA_permissionsEditPanelContent {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.IA_permissionsSearchResult {
  margin-top: 100px;
  clear: both;
}

.IA_permissionsEditPanel h3 {
  margin-top: 0px;
  margin-bottom: 20px;
}