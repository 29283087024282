.IA_profileLogo {
  width: 30px;
  height: 30px;
  border-radius: 30px;
  margin-top: 10px;
  margin-left: 10px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  float: left;
  transition: all 500ms ease-in-out;
}

.IA_profileName {
  width: calc(100% - 150px);
  height: 50px;
  line-height: 50px;
  float: left;
  padding-left: 10px;
  box-sizing: border-box;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  text-overflow: ellipsis;
  box-orient: vertical;
  -webkit-box-orient: vertical;
}
