.IA_profileEditPanel {
  position: relative;
  width: 100%;
  float: left;
}

.IA_profileEditPanelContent {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.IA_profileEditPanel h3 {
  margin-top: 0px;
  margin-bottom: 20px;
}
