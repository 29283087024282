.IA_Pling, .IA_PlingDark {
  height: 100%;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.IA_label {
  height: 30px;
  margin-top: 10px;
  float: left;
  font-size: 14px;
  text-transform: uppercase;
  box-sizing: border-box;
  float: left;
}

.IA_labelImage {
  border-radius: 5px;
  margin: 4px;
  width: 22px;
  height: 22px;
  float: left;
  position: relative;
}

.IA_labelText {
  height: 30px;
  line-height: 30px;
  float: left;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  line-clamp: 1;
  -webkit-line-clamp: 1;
  box-orient: vertical;
  white-space: nowrap;
  max-width: calc(100% - 50px);
  border-radius: 5px;
  padding-right: 20px;
}