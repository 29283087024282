.IA_sendPlingPanel,
.IA_sendPlingPanelDarkMode {
  position: relative;
  width: 100%;
  float: left;
}

.IA_sendPanelContent {
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  float: left;
  position: relative;
}

.IA_templateName {
  font-size: 17px;
  margin-bottom: 10px;
  font-weight: 600;
}

.IA_sendPanelPreview {
  width: 50%;
  float: left;
  position: relative;
  background-color: #777777;
  padding: 40px;
  box-sizing: border-box;
}

.IA_sendPanelPreviewNoCard {
  width: 400px;
  height: 400px;
  border: 1px dashed #eeeeee;
}

.IA_sendPlingPanel .ac-adaptiveCard {
  border: 1px solid lightgray !important;
  padding: 15px !important;
}

.IA_sendPlingPanelDarkMode .ac-adaptiveCard {
  border: 1px solid #000000 !important;
  background-color: #292929 !important;
  color: #ffffff !important;
  padding: 15px !important;
}

.IA_sendPlingPanelDarkMode .ac-adaptiveCard p {
  color: #ffffff !important;
}

@media only screen and (max-device-width: 430px) and (orientation: portrait) {
  .IA_content {
    width: 100%;
    padding: 20px;
  }
}