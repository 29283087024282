.IA_onboardingAddUserPanel {
  position: relative;
  width: 100%;
  float: left;
}

.IA_onboardingAddUserPanelContent {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.IA_onboardingAddUserPanel h3 {
  margin-top: 0px;
  margin-bottom: 20px;
}