.IA_contentArea {
  height: 100%;
  width: 100%;
  position: absolute;
  background-color: #ffffff;
  overflow-y: scroll;
}

.IA_contentAreaWrapper {
  margin: 40px;
  box-sizing: border-box;
  width: calc(100% - 80px);
  height: fit-content;
  position: relative;
  float: left;
}


@media only screen and (max-device-width: 430px) and (orientation: portrait) {
  .IA_contentArea {
    position: absolute;
    top: 50px;
    height: calc(100% - 50px);
    float: left;
    background-color: #ffffff;
    overflow-y: scroll;
  }
}