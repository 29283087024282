.IA_peoplePicker, .IA_imageUploader {
  width: 100%;
  clear: both;
  float: left;
  border-radius: 5px;
  box-sizing: border-box;
  padding-left: 5px;
  position: relative;
}

.IA_SendPling input, .IA_SendPling .textarea {
  outline: none !important;
  background-color: transparent !important;
}

.IA_SendPlingDark input, .IA_SendPling .textarea {
  outline: none !important;
  background-color: #414141 !important;
}

.people-picker {
  background-color: transparent !important;
  outline: none;
}

.people-picker .search-box__input {
  background-color: transparent;
  color: var(--text-color);
}

mgt-people-picker {
  --input-border: 1px solid transparent;
  --input-border-color--hover: transparent !important;
  /* input area border hover color */
  --input-border-color--focus: transparent !important;
  /* input area border hover color */
  --input-background-color: transparent;
  --selected-person-background-color: var(--background-color);
  /* person item background color */
  --color: transparent;
  /* input area border focus color */
}

@media only screen and (max-width: 667px) {
  .message-form {
    width: 95%;
  }
}

.message-form {
  margin: auto;
}

.IA_paginationButtonContainer {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.IA_Pling, .IA_PlingDark {
  height: 100%;
  width: 100%;
  float: left;
  margin-bottom: 20px;
}

.IA_textArea {
  width: 100%;
  height: 250px;
  resize: none;
  font-family: inherit;
  font-size: inherit;
}

.people-picker {
  background-color: transparent !important;
  outline: none;
}

.people-picker .search-box__input {
  background-color: transparent;
  color: var(--text-color);
}

mgt-people-picker {
  --input-border: 1px solid transparent;
  --input-border-color--hover: transparent !important;
  /* input area border hover color */
  --input-border-color--focus: transparent !important;
  /* input area border hover color */
  --input-background-color: transparent;
  --selected-person-background-color: var(--background-color);
  /* person item background color */
  --color: transparent;
  /* input area border focus color */
}

@media only screen and (max-width: 667px) {
  .message-form {
    width: 95%;
  }
}

.message-form {
  margin: auto;
}

.IA_paginationButtonContainer {
  width: 100%;
  height: 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.IA_noContent, .IA_noContentDarkMode {
  font-size: 16px;
  text-align: center;
  width: 100%;
  float: left;
  position: relative;
  margin-top: 40px;
  color: gray;
}

.IA_noContentDarkMode {
  color: lightgray;
}