.IA_Instruction {
  height: 100%;
  width: 100%;
  float: left;
}

.IA_carousel {
  width: 100%;
  position: relative;
  float: left;
  clear: both;
}