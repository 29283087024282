h1 {
    margin-bottom: 10px !important;
}

h3 {
    padding-bottom: 0;
    margin-bottom: 0;
    color: #333333;
    font-weight: lighter;
    font-size: 20px;
}