.IA_settings {
  height: 100%;
  width: 100%;
  float: left;
}

.IA_settingsContentAreaWrapper {
  box-sizing: border-box;
  width: 100%;
  box-sizing: border-box;
  height: 100%;
  position: relative;
  float: left;
  overflow-x: hidden;
  overflow-y: auto;
}