.IA_editTemplatePanel {
  position: relative;
  width: 100%;
  float: left;
}

.IA_content {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding-left: 40px;
  padding-right: 40px;
  box-sizing: border-box;
  overflow: auto;
  padding-bottom: 20px;
}

.IA_content a {
  line-height: 40px;
}

.IA_settings {
  width: 800px;
  float: left;
  position: relative;
}

.IA_preview, .IA_previewDarkMode {
  width: 480px;
  float: left;
  position: relative;
  height: 100%;
  background-color: #777777;
  padding: 40px;
  box-sizing: border-box;
}

.IA_previewDarkMode {
  background-color: #414141;
}

.IA_preview .ac-adaptiveCard {
  border: 1px solid lightgray !important;
  padding: 15px !important;
}

.IA_previewDarkMode .ac-adaptiveCard {
  border: 1px solid #000000 !important;
  background-color: #292929 !important;
  color: #ffffff !important;
  padding: 15px !important;
}

.IA_previewDarkMode .ac-adaptiveCard p {
  color: #ffffff !important;
}

@media only screen and (max-device-width: 430px) and (orientation: portrait) {
  .IA_content {
    width: 100%;
    padding: 20px;
  }
}