.IA_users {
  width: 100%;
  position: relative;
  float: left;
}

.IA_users h1 {
  float: left;
  font-weight: lighter;
}
