.IA_popup .IA_popupBackground,
.IA_popupDarkMode .IA_popupBackground {
  width: 100vw;
  height: 100vh;
  background-color: #ffffff80;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 2000000;
}

.IA_popupDarkMode .IA_popupBackground {
  background-color: #00000080;
}

.IA_popup .IA_headline,
.IA_popupDarkMode .IA_headline {
  clear: both;
  margin-bottom: 10px;
  font-weight: lighter;
  text-align: left;
  font-size: 22px;
  margin-top: -10px;
  float: left;
}

.IA_popupDarkMode .IA_headline {
  color: #A8A8A8;
}

.IA_popup .IA_popupContentBox,
.IA_popupDarkMode .IA_popupContentBox {
  text-align: center;
  position: fixed;
  top: 40%;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 400px;
  max-height: 100vh;
  overflow-y: auto;
  background-color: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.24);
  padding: 20px;
  border-radius: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  text-align: left;
  z-index: 2000001;
  background-position: center;
  background-size: cover;
  font-family: "Segoe UI Web (West European)", Segoe UI, -apple-system, BlinkMacSystemFont, Roboto, Helvetica Neue, sans-serif;
}

.IA_popupDarkMode .IA_popupContentBox {
  background-color: #1f1f1f;
}

@media only screen and (max-device-width: 430px) and (orientation: portrait) {
  .IA_popup .IA_popupContentBox {
    width: calc(100vw - 40px);
  }
}

.IA_closeButton {
  position: relative;
  float: right;
  height: 30px;
  width: 30px;
  background-position: center;
  background-size: 20px;
  background-color: transparent;
  border: none;
  outline: none;
  cursor: pointer;
  background-repeat: no-repeat;
  margin-top: -5px;
  margin-right: -17px;
  opacity: 0.4;
}

.IA_popup .ac-adaptiveCard {
  border: 1px solid lightgray !important;
  padding: 15px !important;
}

.IA_popupDarkMode .ac-adaptiveCard {
  border: 1px solid #000000 !important;
  background-color: #292929 !important;
  color: #ffffff !important;
  padding: 15px !important;
}

.IA_popupDarkMode .ac-adaptiveCard p {
  color: #ffffff !important;
}