ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
.carousel-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.carousel {
  position: relative;
}
.carousel__slide {
  margin-right: auto;
  margin-left: auto;
  display: none;
  max-width: 900px;
  list-style-type: none;
  text-align: center;
}


/* @media only screen and (max-width: 667px) {
  .carousel__slide {
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 5px;
    margin-left: 3%;
    max-width: 75%;
 }
} */
.carousel__slide--active {
  display: block;
}
.carousel-slide__content {
  margin-bottom: 19px;
  font-family: 'Open Sans', 'Trebuchet MS', sans-serif;
  font-size: 16px;
}

.carousel__arrow {
  position: absolute;
  top: 50%;
  display: block;
  color: #111;
  cursor: pointer;
  opacity: 0.75;
  transform: translateY(-50%);
  transition: opacity 0.15s cubic-bezier(0.4, 0, 1, 1);
}
/* .carousel__arrow:focus {
  outline: 0;
}
.carousel__arrow:hover {
  opacity: 0.5;
}
.carousel__arrow--left {
  left: 32px;
}
.carousel__arrow--right {
  right: 32px;
}
@media only screen and (max-width: 667px) {
  .carousel__arrow--left {
    left: 0;
  }
  .carousel__arrow--right {
    right: 0;
  }
  .carousel__slide{
    margin: 0 auto;
  }
} */
.carousel__indicators {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.carousel__indicators li {
  margin-left: 9px;
}
.carousel__indicator {
  display: block;
  width: 0.5rem;
  height: 0.5rem;
  background-color: #111;
  cursor: pointer;
  opacity: 0.15;
  border: none;
  border-radius: 100%;
}
.carousel__indicator:hover {
  opacity: 0.5;
}
.carousel__indicator--active, .carousel__indicator--active:hover {
  opacity: 0.75;
}
