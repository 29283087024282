.IA_templates {
  height: 100%;
  width: 100%;
  float: left;
}

.ui-chat__messageheader {
  padding-bottom: 10px !important;
}

.IA_templateCard, .IA_templateCardDarkMode {
  float: left;
  clear: both;
  padding: 20px 15px 15px 15px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  margin-right: 20px;
  margin-bottom: 20px;
}

.IA_templateCard .ac-adaptiveCard {
  border: 1px solid lightgray !important;
  padding: 15px !important;
}

.IA_templateCardDarkMode .ac-adaptiveCard {
  border: 1px solid #000000 !important;
  background-color: #292929 !important;
  color: #ffffff !important;
  padding: 15px !important;
}

.IA_templateCardDarkMode .ac-adaptiveCard p {
  color: #ffffff !important;
}

