.IA_group {
  width: 100%;
  clear: both;
  margin-bottom: 10px;
  float: left;
}

.IA_line {
  width: calc(50% - 150px);
  height: 1px;
  background-color: #eeeeee;
  float: left;
  margin-top: 8px;
}

.IA_title {
  font-size: 14px;
  cursor: pointer;
  font-weight: bold;
  text-align: center;
  float: left;
}

.IA_groupArea { 
  height: auto;
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
  position: relative;
  float: left;
  margin-bottom: 10px;
}