.IA_welcomeMessagePanel {
  position: relative;
  width: 100%;
  float: left;
  padding: 40px;
}

.IA_welcomeMessagePanel h3 {
  margin-top: 0px;
  margin-bottom: 20px;
}